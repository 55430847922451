export const pageSetHead = (data: object, ogImageUrl: string) => {
  const localizedData = i18nGetLocalizedData(baseStrapiGetAttributes(data)?.seo) || baseStrapiGetAttributes(data)

  useHead({
    title: i18nGetLocalizedTitle(localizedData),
    htmlAttrs: [
      { lang: baseI18nGetActiveIso() },
      { dir: baseI18nGetActiveDir() }
    ]
  })

  const metaObjects = {
    ...baseStrapiGetMetaObject(data, ogImageUrl),
    title: i18nGetLocalizedTitle(localizedData),
    description: i18nGetLocalizedString(localizedData?.descriptions),
    keywords: i18nGetLocalizedString(localizedData?.keywords),
    locale: baseI18nGetActiveIso()
  }

  useSeoMeta(
    basePageGetSeoMetaObject(metaObjects)
  )

  i18nSetRouteMeta(data)
}
